@import '../../node_modules/bootstrap/scss/bootstrap';
@import "../../node_modules/react-toastify/scss/main";


// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 200 to 1000
* {
  font-family: "Nunito Sans", sans-serif !important;
}

body {
  background-color: #F5F6FA !important;
}

.sidebar-min-width-mobile {
  width: 80px;
  background-color: white !important;
}

.sidebarminwidth {
  width: 108px;
}

.h-50 {
  height: 50px !important;
}

.text-center-w {
  text-align: -webkit-center;
}

.text-green {
  color: rgb(0, 168, 154);
}

.text-warning {
  color: #ed6c02 !important;
}

h1 {
  font-size: 22px;
  font-weight: bold;
}

h2 {
  font-size: 18px;
  font-weight: bold;
}

h3 {
  font-size: 16px;
  font-weight: bold;
}

.fs-7 {
  font-size: 14px !important;
}

.fs-8 {
  font-size: 12px !important;
}

.right-4 {
  right: 4px !important;
}

.right-0 {
  right: 0 !important;
}

.left-50 {
  left: 50% !important;
}

.bg-gradient {
  background: linear-gradient(135deg, #3CB0C8, #0c92af) !important;
  color: white; /* Optional: Set text color to white for contrast */
  padding-left: 20px;
  padding-right: 20px;
}

.rounded-bottom-left {
  border-bottom-left-radius: 8px;
}

.rounded-bottom-left {
  border-bottom-right-radius: 8px;
}

.bottom-2 {
  bottom: 2px;
}

.bottom-4 {
  bottom: 4px;
}

.bottom-5 {
  bottom: 40px;
}

.d-ruby {
  display: ruby;
}

.modallogout {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

a {
  text-decoration: none;
}

.right-15 {
  right: 15px;
}

#basic-menu > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
  width: 282px !important;
}

.avatarProfilePicHover {
  filter: brightness(0.5);

}

.avatarProfilePicHoverDiv {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
}

.chat-box {
  padding: 19px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.chat-message {
  margin: 5px 0;
  padding: 8px;
  border-radius: 4px;
  background-color: #e1ffc7;
}

.chat-message.system {
  color: black;
  background-color: #cacdc7;
}

.chat-message.user {
  color: white;
  background-color: green;
}

.chat-input {
  display: flex;
  align-items: center;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.chat-input button {
  padding: 10px 20px;
  border: none;
  background-color: #4CAF50;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #45a049;
}

.reply-box {
  background-color: #e0f7fa;
  border-radius: 15px;
  padding: 10px;
  margin: 10px 0;
  position: relative;
  z-index: 2 !important;

}

.reply-box.user {
  background-color: #335183;
  color: white;
}

.reply-box::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 20px;
  height: 20px;
  background-color: inherit;
  border-bottom-right-radius: 15px;
  box-shadow: -3px 3px 0 3px #cacdc7;
  transform: rotate(45deg);
  z-index: -1;
}

.reply-box.user::before {
  left: auto;
  right: -3px;
  bottom: 6px;
  z-index: -1;
  box-shadow: 3px 3px 0 3px #335183;
  background-color: #335183;
}

.reply-box-user {
  background-color: #335183 !important;
  color: white !important;
}

.reply-box-user:hover {
  background-color: #335183 !important;
  color: white !important;
}

.bg-red {
  background-color: rgb(197, 90, 17) !important;
}

.fs-7 {
  font-size: 10px !important;
  color: rgb(197, 90, 17) !important;
  font-weight: 500 !important;
}

.hover-bg-red:hover {
  background-color: rgb(197, 90, 17) !important;
}

scroll-margin-top-100 {
  scroll-margin-top: 100px !important;
}

.makeStyles-root-8 {
  left: 10px !important;
}

.makeStyles-root-1 {
  width: unset !important;
}

.makeStyles-root-12 {
  right: 10px !important;
}

.makeStyles-root-8, .makeStyles-root-12 {
  // for login image slider
  //opacity: .4 !important;
  background-color: #F4F4F4 !important;

}

.makeStyles-root-5 {
  height: 100vh !important;
}

.makeStyles-root-6 {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50% !important;
  top: 50% !important;
}

.makeStyles-arrow-9::before, .makeStyles-arrow-13::before {
  top: 10px;
}

.MuiFilledInput-input {
  padding: 15px !important;
}

.MuiFilledInput-root {
  background-color: unset !important;
}

.MuiFilledInput-root::before {
  content: unset !important;
}

.Mui-focused::after {
  content: unset !important;
}


.jss8 {
  left: 10px !important;
}

.jss1 {
  width: unset !important;
}

.jss12 {
  right: 10px !important;
}

.jss8, .jss12 {
  // for login image slider
  //opacity: .4 !important;
  background-color: #F4F4F4 !important;

}

.jss5 {
  height: 100vh !important;
}

.jss6 {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50% !important;
  top: 50% !important;
}

.jss9::before, .jss13::before {
  top: 10px;
}

.menuActive:after {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  position: absolute;
  left: -61px;
  content: "";
  background-color: rgb(0, 168, 154);
}

.dir-rtl {
  direction: rtl;
}

.dir-ltr {
  direction: ltr;
}

.w-3 {
  width: 130px;
}

.poweredbyahura {
  font-size: 20px !important;
  color: rgb(0, 168, 154) !important;
}

.prompthistorytitle {
  height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.prompthistorysidetitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.border-right {
  border-right: 0.3px solid #B9B9B9;
  height: 856px;
}

.h-80 {
  height: 80%;
}

.bg-green {
  background-color: rgb(0, 168, 154) !important;
}

.border {
  border-color: rgb(0, 168, 154) !important;
}

.align-content-baseline {
  align-content: baseline;
}

.toolscard {
  position: relative;
  display: inline-block;

  &:not(:hover) {
    transition: all 1s ease;
    background-color: #fdfdfd;
  }

  &:hover {
    transition: all 1s ease;
    background-color: rgb(0, 168, 154);
  }
}


.top-55 {
  top: 55px !important;
}

.text-danger {
  color: rgb(197, 90, 17) !important;
}


.hi-50 {
  height: 50px !important;
}

.ahuraHeaderLogo {
  width: 50px;
}

.bg-unset {
  background-color: unset !important;

  &:hover {
    background-color: unset !important;
  }
}

.slideimg > div > div > div > div {
  height: 100% !important;
  object-fit: cover !important;
}

.slideimg > div > div {
  width: 50% !important;
}

.PaymentMethodWidth {
  width: 150px;
}

.mb-6 {
  margin-bottom: 70px !important;
}

.MuiOutlinedInput-input {
  display: flex !important;
  gap: 20px !important;
  align-items: center !important;
}

.cursorPointer {
  cursor: pointer;
}

.wrap-mode {
  text-wrap-mode: wrap;
}
